@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;600;800&display=swap');

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

ul {
  list-style-position: inside;
}

li:not(:last-child) {
  margin-bottom: 8px;
}
